<template>
  <div class="content">
    <div>
      <a-row :gutter="16">
        <a-col :span="3">
          <a-input v-model="form.name" :placeholder="$t('算法运行.名称')" size="large" />
        </a-col>
        <a-col :span="3">
          <a-input v-model="form.executedBy" :placeholder="$t('算法运行.执行人')" size="large" />
        </a-col>
        <a-col :span="3">
          <a-input v-model="form.studies" :placeholder="$t('算法运行.学习')" size="large" />
        </a-col>
        <a-col :span="3">
          <a-input v-model="form.bgm" :placeholder="$t('算法运行.BGM')" size="large" />
        </a-col>
        <a-col :span="3">
          <a-input v-model="form.filter" :placeholder="$t('算法运行.过滤')" size="large" />
        </a-col>
        <a-col :span="3">
          <a-input v-model="form.model" :placeholder="$t('算法运行.模型')" size="large" />
        </a-col>
        <a-col :span="3">
          <a-date-picker v-model="form.date1" :format="dateFormat" :placeholder="$t('算法运行.开始时间')" show-time size="large" />
        </a-col>
        <a-col :span="3">
          <a-date-picker v-model="form.date2" :format="dateFormat" :placeholder="$t('算法运行.结束时间')" show-time size="large" />
        </a-col>
      </a-row>
      <a-row style="margin-top: 10px">
        <a-col :span="3">
          <a-button block class="btn">{{$t('算法运行.清除')}}</a-button>
        </a-col>
      </a-row>
    </div>
    <div style="margin-top: 16px">
      <a-table :dataSource="dataSource" :columns="columns" :locale="{ emptyText: ' No results were found.' }">
      </a-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      dataSource: [],
      columns: [
        {
          title: this.$t('算法运行.名称'),
          dataIndex: 'id',
          ellipsis: true,
          align: 'center',
        },
        {
          title: this.$t('算法运行.执行人'),
          dataIndex: 'sensorsId',
          ellipsis: true,
          align: 'center',
        },
        {
          title:this.$t('算法运行.BGM'),
          dataIndex: 'studyGroup',
          ellipsis: true,
          align: 'center',
        },
        {
          title:this.$t('算法运行.MARD'),
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
        },
        {
          title: this.$t('算法运行.执行时间'),
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center',
        },
        {
          title: this.$t('算法运行.控制'),
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
      ],
      dateFormat: 'YYYY/MM/DD',
    }
  },
}
</script>

<style scoped lang="less">
.content {
  .btn {
    width: 108px;
    height: 38px;
    align-items: center;
    background-color: #7f62ad;
    border-color: #7f62ad;
    box-shadow: none !important;
    display: flex;
    // white-space: pre;
    color: #fff;
    border-radius: 0.25rem;
    font-weight: 500;
    font-size: 16px;
  }
}
</style>